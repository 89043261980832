interface Props {
  children?: React.ReactNode;
}

export const TableContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="overflow-x-auto -my-2 lg:-mx-8 md:-mx-0 -mx-0">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 px-4">
        <div className="overflow-hidden ring-1 ring-black ring-opacity-5">
          {children}
        </div>
      </div>
    </div>
  );
};
