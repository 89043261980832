interface Props {
  onClick?: () => void;
}

export const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <>
      <div className="flex justify-end">
        <button className="hover:text-red-500 p-2" onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </>
  );
};
