interface Props {
  label: string;
}

export const DataHeader: React.FC<Props> = ({ label }) => {
  return (
    <>
      <div className="font-bold lg:text-base md:text-sm text-xs text-gray-400">
        {label}
      </div>
    </>
  );
};
