import {
  FolderPlusIcon,
  InformationCircleIcon,
  PaperClipIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { EstimateAndQuotePDF } from './EstimateAndQuotePDF';
import { EstimateAndQuoteSend } from './EstimateAndQuoteSend';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import {
  CallToActionContainer,
  CallToActionItems,
  FormButtonsContainer,
} from '@components/Container';
import {
  Details,
  MainBoxDetailsColumn,
  DataHeader,
  DetailsButton,
  DetailTableDataLeft,
  DetailTableDataCenter,
  DetailTableDataRight,
  DetailTableHeaderLeft,
  DetailTableHeaderCenter,
  DetailTableHeaderRight,
  MoreAction,
  DetailsSpacer,
  DetailsButtonSolid,
} from '@components/Details';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { TuosLogo } from '@components/Icons';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { Table, TableContainer } from '@components/TableContainers';
import TextWrapper from '@components/TextWrapper';
import { routes } from '@config/routes';
import {
  BillingAddressResultViewModel,
  billingAddressDefaultValue,
} from '@models/BillingAddress';
import { ResultViewModel } from '@models/common/ResultViewModel';
import {
  CustomerResultViewModel,
  customerDefaultValue,
} from '@models/Customer';
import {
  ConvertToInvoiceResultViewModel,
  EstimateAndQuoteResultViewModel,
  estimateAndQuoteDefaultValue,
  getStatusText,
} from '@models/EstimateAndQuote';
import { EstimateAndQuoteDetailViewModel } from '@models/EstimateAndQuoteDetail';
import { EstimateAndQuoteDetailSalesTaxViewModel } from '@models/EstimateAndQuoteDetailSalesTax';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { EstimateAndQuoteService } from '@services/EstimateAndQuote';
import { formatAsCurrency } from '@utils/numberFormat';
import { toTitleCase } from '@utils/titleCase';

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  const dateTimeString = new Date(date).toLocaleString('en-US', options);

  return `${dateTimeString}`;
};

export const EstimateAndQuoteDetails = () => {
  const [data, setData] = useState(estimateAndQuoteDefaultValue);
  const { id } = useParams();
  const [customer, setCustomer] = useState(customerDefaultValue);

  const [showCustomerDetails, setCustomerDetails] = useState(false);

  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const [showEditCustomers, setShowEditCustomers] = useState(false);

  const [showSend, setShowSend] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [estimateDetails, setEstimateDetails] = useState<
    EstimateAndQuoteDetailViewModel[]
  >([]);

  const loadEstimateDetails = () => {
    EstimateAndQuoteService.getEstimateDetails(Number(id)).then((result) => {
      setEstimateDetails(result.data);
    });
  };

  const loadData = () => {
    EstimateAndQuoteService.getById(Number(id))
      .then(
        (response) => response.data as Promise<EstimateAndQuoteResultViewModel>
      )
      .then((result) => {
        if (result.isSuccess) {
          setData(result.data);
          setIsLoading(false);

          if (result.data.billingAddressID) {
            BillingAddressService.getById(result.data.billingAddressID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => setCustomerAddress(addressResult.data));
          }

          if (result.data.customerID) {
            CustomerService.getById(result.data.customerID)
              .then(
                (customerResponse) =>
                  customerResponse.data as Promise<CustomerResultViewModel>
              )
              .then((customerResult) => {
                setCustomer(customerResult.data);
              });
          }

          console.log('Success: Fetched the record');
        } else {
          console.log(
            `Error: Failed to get the record. ${result.errorMessage}`
          );
          setIsLoading(true);
        }
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
      loadEstimateDetails();
    }
  }, []);

  useEffect(() => {
    console.log('Estimate Details', estimateDetails);
  }, [estimateDetails]);

  const handleEdit = (rowId: number) => {
    navigate(`${routes.RNC_ESTIMATES}/${rowId}/edit`, {
      state: { from: location },
    });
  };

  const handleEditCustomer = (rowId: any) => {
    navigate(`${routes.RNC_CUSTOMERS}/${rowId}/edit`, {
      state: { from: location },
    });
  };

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = estimateDetails.map((o) =>
      o.estimateDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({
          ...salesTaxes,
          salesTaxKey: `${salesTaxes.salesTaxAbbreviation} (${salesTaxes.rate}%)`,
        }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxKey'
    ) as EstimateAndQuoteDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const handlePrint = () => {
    toast.success('Print selected Estimate', {
      position: toast.POSITION.TOP_LEFT,
      theme: 'colored',
      autoClose: 1000,
    });
  };

  const [showPDF, setShowPDF] = useState(false);

  const handleDownloadPDF = () => {
    const message = `Generating PDF`;
    toast.info(`${message}`, {
      position: toast.POSITION.TOP_LEFT,
      theme: 'colored',
      autoClose: 300,
    });
    setTimeout(() => {
      setShowPDF(true);
    }, 1000);
  };

  const downloadPDF = () => {
    const element = document.getElementById('pdf-content');
    if (element) {
      html2pdf()
        .from(element)
        .set({
          margin: 0.3,
          filename: `Estimate No: ${data.estimateNumber}.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .save()
        .then(() => {
          console.log('PDF successfully downloaded.');
          setShowPDF(false);
        })
        .catch((error: any) => {
          console.error('Error during PDF generation:', error);
        });
    } else {
      console.error('No content found to download as PDF.');
    }
  };

  const duplicateRecord = (rowId: number) => {
    EstimateAndQuoteService.duplicate(rowId).then((response) => {
      if (response.status == 200) {
        const message = `Successfuly duplicated.`;
        toast.success(`${message}`, {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate(`${routes.RNC_ESTIMATES}`);
        }, 1000);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Estimate duplication encountered an error. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const convertRecordToInvoice = () => {
    EstimateAndQuoteService.convertToInvoice(Number(id))
      .then(
        (response) => response.data as Promise<ConvertToInvoiceResultViewModel>
      )
      .then((result) => {
        if (result.isSuccess) {
          const message = `Successfuly converted.`;
          toast.success(`${message}`, {
            position: toast.POSITION.TOP_LEFT,
            theme: 'colored',
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate(`${routes.RNC_INVOICES}/${result.data.newInvoiceId}`);
          }, 1000);
        } else {
          const message = `Estimate conversion encountered an error. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        }
      });
  };

  const handleApprove = (rowId: number) => {
    EstimateAndQuoteService.approve(rowId).then((response) => {
      if (response.status == 200) {
        toast.info(`${data.estimateNumber} has been approved.`, {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `${data.estimateNumber} encountered an error. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const deleteRecord = (rowId: number) => {
    EstimateAndQuoteService.delete(rowId).then((response) => {
      if (response.status == 204) {
        toast.error('Successfully deleted the record', {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 500,
        });
        setTimeout(() => {
          navigate(`${routes.RNC_ESTIMATES}`);
        }, 500);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const [showDelete, setDelete] = useState(false);

  const handleDelete = () => {
    setDelete(true);
  };

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <ToastContainer />
        <GridHeaderMenuTitle>
          <div className="lg:text-xl md:text-lg text-sm font-bold">
            {data.estimateNumber}
          </div>

          <div className="flex flex-row space-x-2">
            <MoreAction
              dropDownActions={[
                {
                  title: 'Duplicate Record',
                  onClick: () => duplicateRecord(data.id),
                },
                {
                  showTopSeparator: true,
                  title: 'Print',
                  onClick: () => handlePrint(),
                },
                {
                  title: 'Approve',
                  onClick: () => handleApprove(data.id),
                },
                {
                  title: 'Export to PDF',
                  onClick: () => handleDownloadPDF(),
                },
              ]}
              deleteAction={[
                {
                  title: 'Delete',
                  onClick: () => handleDelete(),
                },
              ]}
            />

            <span className="lg:flex md:hidden hidden">
              <PrimaryButton
                onClick={() => {
                  navigate(`${routes.RNC_ESTIMATES}/create`, {
                    state: { from: location },
                  });
                }}
              >
                Create new estimate
              </PrimaryButton>
            </span>

            <button
              onClick={() => {
                navigate(`${routes.RNC_ESTIMATES}/create`, {
                  state: { from: location },
                });
              }}
              className="lg:hidden flex items-center md:flex rounded-full border-2 border-primary-500 text-white bg-primary-500 md:px-2 md:py-2 py-1 px-1.5"
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          </div>
        </GridHeaderMenuTitle>
        <Details>
          {/* Desktop and Tablet */}
          <MainBoxDetailsColumn>
            <div className="lg:flex md:flex hidden col-span-1 grid-cols-2 lg:space-x-24 md:space-x-16">
              <div className="col-span-1">
                <DataHeader label="Status" />
                <div className="inline-flex items-center rounded-md lg:px-4 lg:py-1 md:px-2 md:py-1 lg:text-base md:text-base text-sm font-medium ring-1 ring-inset ring-primary-600/20">
                  {getStatusText(data.status)}
                </div>
              </div>
              <div className="col-span-1">
                <DataHeader label="Customer" />
                <div className="text-primary-500 lg:text-lg md:text-sm text-xs hover:text-primary-600 font-bold">
                  <button
                    className="flex lg:gap-x-2 md:gap-x-1 gap-x-0.5 items-center uppercase text-start"
                    onClick={() => setCustomerDetails(true)}
                  >
                    <div>{customer.companyName}</div>
                    <InformationCircleIcon className="lg:w-6 lg:h-6 md:w-5 md:h-5" />
                  </button>
                </div>
              </div>
            </div>
            <div className="lg:flex md:flex hidden col-span-1 grid-cols-2 lg:space-x-24 md:space-x-16">
              <div className="col-span-1">
                <DataHeader label="Estimate Amount" />
                <div className="lg:text-lg md:text-sm text-sm font-bold">
                  {formatAsCurrency(
                    estimateDetails.reduce(
                      (acc, cur) => acc + cur.subTotal,
                      0
                    ) +
                      estimateDetails
                        .map((o) =>
                          o.estimateDetailSalesTaxes.reduce(
                            (acc, cur) => acc + cur.salesTaxAmount,
                            0
                          )
                        )
                        .reduce((acc, cur) => acc + cur, 0)
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <DataHeader label="Valid Until" />
                <div className="lg:text-lg md:text-sm sm:text-sm">
                  {formatDate(data.dueDate)}
                </div>
              </div>
            </div>
          </MainBoxDetailsColumn>

          {/* Mobile */}
          <div className="lg:hidden md:hidden block space-y-4">
            <MainBoxDetailsColumn>
              <div className="w-full flex col-span-2 grid-cols-1 justify-between">
                <div className="col-span-1">
                  <DataHeader label="Status" />
                  <div className="inline-flex items-center rounded-md p-1.5 text-xs font-medium ring-1 ring-inset ring-primary-600/20">
                    {getStatusText(data.status)}
                  </div>
                </div>
                <div className="col-span-1">
                  <DataHeader label="Customer" />
                  <div className="text-primary-500 text-xs hover:text-primary-600 font-bold">
                    <button
                      className="flex gap-x-0.5 items-center uppercase text-start"
                      onClick={() => setCustomerDetails(true)}
                    >
                      <div>{customer.companyName}</div>
                      <InformationCircleIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            </MainBoxDetailsColumn>

            <MainBoxDetailsColumn>
              <div className="w-full flex col-span-2 grid-cols-1 justify-between">
                <div className="col-span-1">
                  <DataHeader label="Estimate Amount" />
                  <div className="text-xs font-semibold">
                    {formatAsCurrency(
                      estimateDetails.reduce(
                        (acc, cur) => acc + cur.subTotal,
                        0
                      ) +
                        estimateDetails
                          .map((o) =>
                            o.estimateDetailSalesTaxes.reduce(
                              (acc, cur) => acc + cur.salesTaxAmount,
                              0
                            )
                          )
                          .reduce((acc, cur) => acc + cur, 0)
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <DataHeader label="Valid Until" />
                  <div className="text-xs">{formatDate(data.dueDate)}</div>
                </div>
              </div>
            </MainBoxDetailsColumn>
          </div>

          <div className="">
            <CallToActionContainer>
              <CallToActionItems>
                <div className="space-y-3">
                  <div className="flex text-primary-500 lg:text-2xl md:text-lg text-sm items-center lg:space-x-4 md:space-x-3 space-x-2">
                    <FolderPlusIcon className="lg:w-7 lg:h-7 md:w-6 md:h-6 w-5 h-5" />
                    <div className="text-gray-400 font-normal">Create</div>
                  </div>

                  <div className="lg:text-base md:text-sm text-sm">
                    <span className="font-semibold">Created: </span>
                    {formatDateTime(data.date)}
                  </div>
                </div>

                <div>
                  <DetailsButton onClick={() => handleEdit(data.id)}>
                    Edit Estimate
                  </DetailsButton>
                </div>
              </CallToActionItems>
            </CallToActionContainer>

            <DetailsSpacer />

            <CallToActionContainer>
              <CallToActionItems>
                <div className="space-y-3">
                  <div className="flex text-primary-500 lg:text-2xl md:text-lg text-sm items-center lg:space-x-4 md:space-x-3 space-x-2">
                    <PaperClipIcon className="lg:w-7 lg:h-7 md:w-6 md:h-6 w-5 h-5" />
                    <div className="text-gray-400 font-normal">Send</div>
                  </div>

                  <div className="lg:text-base md:text-sm text-sm">
                    <span className="font-semibold">Last Sent: </span>
                    {data.status}
                  </div>
                </div>

                <div>
                  <DetailsButtonSolid
                    onClick={() => {
                      setShowSend(true);
                    }}
                  >
                    Send Estimate
                  </DetailsButtonSolid>
                </div>
              </CallToActionItems>
            </CallToActionContainer>

            <DetailsSpacer />

            <CallToActionContainer>
              <CallToActionItems>
                <div className="space-y-3">
                  <div className="flex text-primary-500 lg:text-2xl md:text-lg text-sm items-center lg:space-x-4 md:space-x-3 space-x-2">
                    <FolderPlusIcon className="lg:w-7 lg:h-7 md:w-6 md:h-6 w-5 h-5" />
                    <div className="text-gray-400 font-normal">
                      Convert to Invoice
                    </div>
                  </div>
                </div>

                <div>
                  <DetailsButtonSolid
                    onClick={() => {
                      convertRecordToInvoice();
                    }}
                  >
                    Convert
                  </DetailsButtonSolid>
                </div>
              </CallToActionItems>
            </CallToActionContainer>
          </div>

          <div className="shadow-md border">
            <div className="lg:p-8 md:p-6 p-4 flex justify-between">
              {/* Desktop */}
              <div className="lg:flex md:hidden hidden items-center">
                <TuosLogo height="100" width="75" />
              </div>
              {/* Tablet */}
              <div className="md:flex lg:hidden hidden items-center">
                <TuosLogo height="75" width="56.25" />
              </div>
              {/* Mobile */}
              <div className="flex lg:hidden md:hidden items-center">
                <TuosLogo height="56.25" width="42.1875" />
              </div>
              <div className="lg:text-xl md:text-base text-sm font-semibold text-end text-gray-500">
                ESTIMATE
                <div className="text-black lg:text-lg md:text-base text-sm">
                  Tuos Development <br />
                  <span className="lg:text-sm md:text-sm text-xs">Canada</span>
                </div>
              </div>
            </div>

            <div className="border-t-2 border-gray-700 lg:py-4 lg:px-8 md:py-4 md:px-6 py-2 px-4">
              <div className="lg:flex md:flex block grid-cols-2 justify-between">
                <div className="lg:col-span-1 md:col-span-1 col-span-2">
                  <div className="lg:text-base md:text-sm text-sm font-semibold text-gray-500">
                    Bill to
                  </div>
                  <div className="lg:text-base md:text-sm text-sm font-semibold uppercase">
                    {customer.companyName}
                  </div>
                  <div className="lg:text-base md:text-sm text-xs">
                    <span className="uppercase">
                      {customer.primaryContact?.firstname}{' '}
                      {customer.primaryContact?.lastname}
                    </span>
                    <br />
                    {toTitleCase(
                      `${customerAddress.address1} ${customerAddress.address2} ${customerAddress.city}`
                    )}
                    <br />
                    {toTitleCase(
                      `${customerAddress.state} ${customerAddress.postalCode}`
                    )}
                    <br />
                    {toTitleCase(`${customerAddress.country}`)}
                    <br />
                    <br />
                    {customer.primaryContact?.phone}
                    <br />
                    <span className="lowercase">
                      {customer.primaryContact?.email}
                    </span>
                  </div>
                </div>

                <div className="lg:hidden md:hidden flex h-4"></div>

                <div className="lg:col-span-1 md:col-span-1 lg:text-base md:text-sm text-xs col-span-2 space-y-1">
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-2 pr-1">
                    <div>Estimate Number:</div>
                    <div className="font-normal text-end">
                      {data.estimateNumber}
                    </div>
                  </div>
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-2 pr-1">
                    <div>Estimate Date:</div>
                    <div className="font-normal text-end">
                      {formatDate(data.date)}
                    </div>
                  </div>
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-2 pr-1">
                    <div>Valid until:</div>
                    <div className="font-normal text-end">
                      {formatDate(data.dueDate)}
                    </div>
                  </div>
                  <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-2 pr-1 bg-gray-300 py-2">
                    <div>Grand Total(CAD):</div>
                    <div className="font-normal text-end">
                      {formatAsCurrency(
                        estimateDetails.reduce(
                          (acc, cur) => acc + cur.subTotal,
                          0
                        ) +
                          estimateDetails
                            .map((o) =>
                              o.estimateDetailSalesTaxes.reduce(
                                (acc, cur) => acc + cur.salesTaxAmount,
                                0
                              )
                            )
                            .reduce((acc, cur) => acc + cur, 0)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-4">
              <div className="max-w-5x1 mx-auto">
                <TableContainer>
                  <Table>
                    <thead className="bg-primary-100">
                      <tr>
                        <DetailTableHeaderLeft>
                          <span className="pl-2">Item</span>
                        </DetailTableHeaderLeft>

                        <DetailTableHeaderCenter></DetailTableHeaderCenter>
                        <DetailTableHeaderCenter>
                          Quantity
                        </DetailTableHeaderCenter>
                        <DetailTableHeaderCenter>Price</DetailTableHeaderCenter>
                        <DetailTableHeaderRight>
                          <span className="pr-2">Amount</span>
                        </DetailTableHeaderRight>
                      </tr>
                    </thead>
                    <tbody>
                      {estimateDetails.map((row, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? ' bg-white' : 'bg-green-50'
                          }`}
                        >
                          <DetailTableDataLeft>
                            <div className="pl-2 ">
                              <div className="font-semibold text-start uppercase">
                                <TextWrapper text={row.product?.name || ''} />
                              </div>
                            </div>
                            <div className="font-light pl-2">
                              <TextWrapper text={row.description || ''} />
                            </div>
                          </DetailTableDataLeft>
                          <DetailTableDataCenter></DetailTableDataCenter>
                          <DetailTableDataCenter>
                            <span className="font-medium">{row.quantity}</span>
                          </DetailTableDataCenter>
                          <DetailTableDataCenter>
                            <span className="font-medium">
                              {formatAsCurrency(row.price)}
                            </span>
                          </DetailTableDataCenter>
                          <DetailTableDataRight>
                            <span className="font-medium">
                              {formatAsCurrency(row.subTotal)}
                            </span>
                          </DetailTableDataRight>
                        </tr>
                      ))}

                      <Space />

                      <tr>
                        <td colSpan={6}>
                          <div className="space-y-1">
                            <div className="flex justify-end mr-6 lg:text-sm md:text-sm text-xs">
                              <div className="font-bold mr-4">SubTotal:</div>

                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  estimateDetails.reduce(
                                    (acc, cur) => acc + cur.subTotal,
                                    0
                                  )
                                )}
                              </div>
                            </div>

                            {Object.entries(getSalesTaxesBySalesTaxID()).map(
                              ([taxTitle, details]) => (
                                <div
                                  className="flex justify-end mr-6 lg:text-sm md:text-sm text-xs"
                                  key={taxTitle}
                                >
                                  <div className="font-medium mr-4">
                                    {taxTitle}
                                  </div>

                                  <div className="w-24 text-right">
                                    {formatAsCurrency(
                                      details.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )}
                                  </div>
                                </div>
                              )
                            )}

                            <div className="flex justify-end mr-6 pt-2 pl-96 lg:text-sm md:text-sm text-xs">
                              <div className="w-full ml-96 flex justify-end border-t border-gray-600 py-2 border-b-4">
                                <div className="font-bold mr-4">Total Tax:</div>
                                <div className="w-24 text-right">
                                  {formatAsCurrency(
                                    estimateDetails
                                      .map((o) =>
                                        o.estimateDetailSalesTaxes.reduce(
                                          (acc, cur) =>
                                            acc + cur.salesTaxAmount,
                                          0
                                        )
                                      )
                                      .reduce((acc, cur) => acc + cur, 0)
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-end mr-6 lg:text-sm md:text-sm text-xs py-4">
                              <div className="font-bold mr-4">Grand Total:</div>
                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  estimateDetails.reduce(
                                    (acc, cur) => acc + cur.subTotal,
                                    0
                                  ) +
                                    estimateDetails
                                      .map((o) =>
                                        o.estimateDetailSalesTaxes.reduce(
                                          (acc, cur) =>
                                            acc + cur.salesTaxAmount,
                                          0
                                        )
                                      )
                                      .reduce((acc, cur) => acc + cur, 0)
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Details>

        {showCustomerDetails && (
          <>
            <Modal show={true}>
              <div className="flex lg:text-base md:text-base text-sm lg:pl-4 md:pl-4 pl-2 justify-between items-center">
                <div className="w-full">Customer Details</div>
                <div>
                  <CloseButton onClick={() => setCustomerDetails(false)} />
                </div>
              </div>
              <Box>
                <div className="px-6 pt-2 pb-8 space-y-6">
                  <div className="lg:text-base md:text-base text-sm">
                    <div className="font-medium">
                      {customer.companyName}
                      Zirkels Solutions
                    </div>
                    <div>
                      {customer.primaryContact?.firstname}{' '}
                      {customer.primaryContact?.lastname} <br />
                      {customer.primaryContact?.email}
                    </div>
                    <br />
                    <div>
                      Tel:
                      {customer.primaryContact?.phone}
                    </div>
                  </div>

                  <div className="flex space-x-5">
                    <DetailsButton
                      onClick={() => handleEditCustomer(data.customerID)}
                    >
                      Edit Details
                    </DetailsButton>

                    <DetailsButtonSolid
                      onClick={() => {
                        navigate(`${routes.RNC_CUSTOMERS}/${data.customerID}`);
                      }}
                    >
                      View Profile
                    </DetailsButtonSolid>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}

        {showEditCustomers && (
          <>
            <Modal show={true}>
              <div className="flex pl-4 justify-between items-center">
                <div className="w-full">Edit Customer Details</div>
                <div>
                  <CloseButton onClick={() => setShowEditCustomers(false)} />
                </div>
              </div>
            </Modal>
          </>
        )}

        {showSend && (
          <>
            <Modal show={true}>
              <EstimateAndQuoteSend
                onDownload={() => downloadPDF()}
                estimateId={data.id}
                estimateName={data.estimateNumber}
                onClose={() => setShowSend(false)}
              />
            </Modal>
          </>
        )}

        {showDelete && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setDelete(false)} />

              <Box size="medium">
                <div className="px-4">
                  <span>Are you sure to delete this estimate?</span>
                </div>

                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setDelete(false)} />
                    <OkButton onClick={() => deleteRecord(data.id)}>
                      Delete
                    </OkButton>
                  </div>
                </FormButtonsContainer>
              </Box>
            </Modal>
          </>
        )}

        {showPDF && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setShowPDF(false)} />

              <Box size="medium">
                <div className="px-4 pb-2">
                  <span>Your estimate is now ready for download</span>
                </div>

                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setShowPDF(false)} />
                    <OkButton onClick={() => downloadPDF()}>Download</OkButton>
                  </div>
                </FormButtonsContainer>
              </Box>
            </Modal>
          </>
        )}

        {/* pdf export */}
        <EstimateAndQuotePDF estimateAndQuotesId={data.id} />
        <ToastContainer />
      </>
    );
  }
};
