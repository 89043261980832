interface Props {
  children?: React.ReactNode;
  onClick: () => void;
}

export const DetailsButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <>
      <button
        onClick={onClick}
        className="lg:text-base md:text-sm text-xs rounded-full border-2 hover:border-primary-700 border-primary-500 text-primary-500 lg:px-8 lg:py-1 md:px-8 md:py-1 px-3 py-1 hover:text-primary-700"
      >
        {children}
      </button>
    </>
  );
};
