interface Props {
  children?: React.ReactNode;
}

export const Details: React.FC<Props> = ({ children }) => {
  return (
    <div className="lg:px-8 md:px-6 px-0">
      <div className="lg:py-8 md:py-6 py-4 lg:space-y-8 md:space-y-6 space-y-4">
        {children}
      </div>
    </div>
  );
};
