import React from 'react';

interface IProps {
  children: React.ReactNode;
}

export const GridHeaderMenuTitle: React.FC<IProps> = ({ children }) => {
  return (
    <div className="flex items-center justify-between lg:px-5 md:px-4 px-1.5 lg:pb-2 ">
      {children}
    </div>
  );
};
